<template>
  
  <div
    class="
      page-container
      w-screen
      h-vh-9/10
      relative
      flex flex-wrap
      justify-center
    "
  >
    <loader v-if="isLoading"/>

    <div
      class="container flex flex-1 justify-center flex-wrap"
      :class="{ 'opacity-0': !showItems }"
    >
      <div class="w-full my-5 text-2xl text-center text-primary uppercase">
        Servicios y Siniestros
        <link rel="shortcut icon" href="//static.base64.guru/uploads/images/1x1.gif" />
      </div>
      <div
        class="
          Accordion
          flex-auto
          w-screen
          justify-center
          flex-shrink-0
          max-h-8/10-screen
          sm:max-h-8/10-screen
          overflow-scroll
          px-5
          sm:px-10
          pb-32
          shadow
          z-0
        "
        ref="scroll"
      >
        <expander
          style="height: 0px !important"
          class="z-0 opacity-0 overflow-hidden"
        >
        </expander>



        <template v-if="isMobileView">
          <expander
            v-for="(indServiceClaim, index) in servicesClaimsList"
            :key="'q-mobile' + index"
            :title="indServiceClaim.title"
            :height="
              getScaleImgValues(maxWidth, indServiceClaim.width_xs, indServiceClaim.height_xs)
                .h
            "
          >


            <button style="display:flex; justify-content: center; margin-bottom: 15px; align-items: center;" v-if="indServiceClaim.categoryid!=0" 
            @click="download_pdfs(indServiceClaim.categoryid)" type="button" class="btn_download_documents">Descargar Archivos 
            <span style="margin-left:10px;">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-filetype-pdf" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M14 4.5V14a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5L14 4.5ZM1.6 11.85H0v3.999h.791v-1.342h.803c.287 0 .531-.057.732-.173.203-.117.358-.275.463-.474a1.42 1.42 0 0 0 .161-.677c0-.25-.053-.476-.158-.677a1.176 1.176 0 0 0-.46-.477c-.2-.12-.443-.179-.732-.179Zm.545 1.333a.795.795 0 0 1-.085.38.574.574 0 0 1-.238.241.794.794 0 0 1-.375.082H.788V12.48h.66c.218 0 .389.06.512.181.123.122.185.296.185.522Zm1.217-1.333v3.999h1.46c.401 0 .734-.08.998-.237a1.45 1.45 0 0 0 .595-.689c.13-.3.196-.662.196-1.084 0-.42-.065-.778-.196-1.075a1.426 1.426 0 0 0-.589-.68c-.264-.156-.599-.234-1.005-.234H3.362Zm.791.645h.563c.248 0 .45.05.609.152a.89.89 0 0 1 .354.454c.079.201.118.452.118.753a2.3 2.3 0 0 1-.068.592 1.14 1.14 0 0 1-.196.422.8.8 0 0 1-.334.252 1.298 1.298 0 0 1-.483.082h-.563v-2.707Zm3.743 1.763v1.591h-.79V11.85h2.548v.653H7.896v1.117h1.606v.638H7.896Z"/>
              </svg>
            </span>
            </button>



            <div v-if="indServiceClaim.categoryid!=0" style="height: 15px; text-align: center; border-radius: 10px; font-size: 12px; width: 100%; position:absolute; bottom:0;">
              Se abrirá una nueva ventana por cada archivo.
            </div>

            <div>
                <div class="servicesAndClaims_contact_container">
                  <div style="display:flex;">

                    <div style="
                      diplay:flex; 
                      align-items:center; 
                      justify-content:center;
                      width:20%; 
                      height:20%"
                    >
                    
                    <img
                      :src="indServiceClaim.img_insurance"
                      :width="
                        getScaleImgValues(
                          maxWidth,
                          indServiceClaim.width_xs,
                          indServiceClaim.height_xs
                        ).w
                      "
                      :height="
                        getScaleImgValues(
                          maxWidth,
                          indServiceClaim.width_xs,
                          indServiceClaim.height_xs
                        ).h
                      "
                      class="w-full"
                    />

                    </div>
                    <div id="servicesAndClaims_titleContact_container"
                      style="
                        padding:20px;
                        width:100%;"
                    >
                      <div
                      style="
                        display:flex;
                      ">
                    
                      <font-awesome-icon
                        :icon="['fas', 'user']"
                        class="fa-lg text-primary"
                      />
                      <h1 style="margin-left:8px;">Contacto</h1>
                      </div>

                      <h2
                        style="font-weight: normal; margin-top:20px;"
                      >
                          {{ indServiceClaim.contact }}
                      </h2>
                    </div>


                  </div>
                </div>
                  <div
                  v-for="service in indServiceClaim.body"
                  :key = service.name
                  class="justify"
                  >
                  
                    <div class="firstTitle">
                      <h1 class="main_title text-primary">{{service.firstTitle}}<br></h1>
                      <h2 class="main_description">{{service.firstDescription}}<br></h2>
                      <ul 
                        v-for="element in service.firstObjectDescription"
                        :key = element.name
                        class="main_object_description"
                      >
                        <li>» {{element}} </li>
                      </ul>
                      <br>
                    </div>

                    <div class="secondTitle">
                      <h1 class="main_title text-primary">{{service.secondTitle}}<br></h1>
                      <h2 class="main_description">{{service.secondDescription}}<br></h2>
                      <ul
                        v-for="element in service.secondObjectDescription"
                        :key = element.name
                        class="main_object_description"
                      >
                        <li>» {{element}} </li>
                      </ul>
                      <br>
                    </div>

                    <div class="thirdTitle">
                      <h1 class="main_title text-primary">{{service.thirdTitle}}<br></h1>
                      <h2 class="main_description">{{service.thirdDescription}}<br></h2>
                      <ul
                        v-for="element in service.thirdObjectDescription"
                        :key = element.name
                        class="main_object_description"
                      >
                        <li>» {{element}} </li>
                      </ul>
                      <br>
                    </div>
                  </div>
            </div>
          </expander>
        </template>









        <template v-else>
          <expander
            v-for="(indServiceClaim , index) in servicesClaimsList"
            :key="'q-' + index"
            :title="indServiceClaim.title"
            :height="
              getScaleImgValues(maxWidth, indServiceClaim.width_xl, indServiceClaim.height_xl)
                .h
            "
          >
            <!-- <img
              :src="indServiceClaim.imagen_xl"
              :width="
                getScaleImgValues(
                  maxWidth,
                  indServiceClaim.width_xl,
                  indServiceClaim.height_xl
                ).w
              "
              :height="
                getScaleImgValues(
                  maxWidth,
                  indServiceClaim.width_xl,
                  indServiceClaim.height_xl
                ).h
              "
              class="w-full"
            /> -->

            <button style="display:flex; justify-content: center; align-items: center;" v-if="indServiceClaim.categoryid!=0" 
            @click="download_pdfs(indServiceClaim.categoryid)" type="button" class="btn_download_documents">Descargar Archivos 
            <span style="margin-left:10px;">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-filetype-pdf" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M14 4.5V14a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5L14 4.5ZM1.6 11.85H0v3.999h.791v-1.342h.803c.287 0 .531-.057.732-.173.203-.117.358-.275.463-.474a1.42 1.42 0 0 0 .161-.677c0-.25-.053-.476-.158-.677a1.176 1.176 0 0 0-.46-.477c-.2-.12-.443-.179-.732-.179Zm.545 1.333a.795.795 0 0 1-.085.38.574.574 0 0 1-.238.241.794.794 0 0 1-.375.082H.788V12.48h.66c.218 0 .389.06.512.181.123.122.185.296.185.522Zm1.217-1.333v3.999h1.46c.401 0 .734-.08.998-.237a1.45 1.45 0 0 0 .595-.689c.13-.3.196-.662.196-1.084 0-.42-.065-.778-.196-1.075a1.426 1.426 0 0 0-.589-.68c-.264-.156-.599-.234-1.005-.234H3.362Zm.791.645h.563c.248 0 .45.05.609.152a.89.89 0 0 1 .354.454c.079.201.118.452.118.753a2.3 2.3 0 0 1-.068.592 1.14 1.14 0 0 1-.196.422.8.8 0 0 1-.334.252 1.298 1.298 0 0 1-.483.082h-.563v-2.707Zm3.743 1.763v1.591h-.79V11.85h2.548v.653H7.896v1.117h1.606v.638H7.896Z"/>
              </svg>
            </span>
            </button>


            <div v-if="indServiceClaim.categoryid!=0" style="height: 15px; text-align: center; border-radius: 10px; font-size: 12px; width: 100%; position:absolute; bottom:0;">
              Se abrirá una nueva ventana por cada archivo.
            </div>

            <div>
                <div class="servicesAndClaims_contact_container">
                  <div style="display:flex;">

                    <div style="
                      diplay:flex; 
                      align-items:center; 
                      justify-content:center;
                      width:20%; 
                      height:20%"
                    >
                    
                    <img
                      :src="indServiceClaim.img_insurance"
                      :width="
                        getScaleImgValues(
                          maxWidth,
                          indServiceClaim.width_xl,
                          indServiceClaim.height_xl
                        ).w
                      "
                      :height="
                        getScaleImgValues(
                          maxWidth,
                          indServiceClaim.width_xl,
                          indServiceClaim.height_xl
                        ).h
                      "
                      class="w-full"
                    />

                    </div>
                    <div id="servicesAndClaims_titleContact_container"
                      style="
                        padding:20px;
                        width:100%;"
                    >
                      <div
                      style="
                        display:flex;
                      ">
                    
                      <font-awesome-icon
                        :icon="['fas', 'user']"
                        class="fa-lg text-primary"
                      />
                      <h1 style="margin-left:8px;">Contacto</h1>
                      </div>

                      <h2
                        style="font-weight: normal; margin-top:20px;"
                      >
                          {{ indServiceClaim.contact }}
                      </h2>
                    </div>


                  </div>
                </div>

                  <div
                  v-for="service in indServiceClaim.body"
                  :key = service.name
                  class="justify"
                  >
                  
                    <div class="firstTitle">
                      <h1 class="main_title text-primary">{{service.firstTitle}}<br></h1>
                      <h2 class="main_description">{{service.firstDescription}}<br></h2>
                      <ul 
                        v-for="element in service.firstObjectDescription"
                        :key = element.name
                        class="main_object_description"
                      >
                        <li>» {{element}} </li>
                      </ul>
                      <br>
                    </div>

                    <div class="secondTitle">
                      <h1 class="main_title text-primary">{{service.secondTitle}}<br></h1>
                      <h2 class="main_description">{{service.secondDescription}}<br></h2>
                      <ul
                        v-for="element in service.secondObjectDescription"
                        :key = element.name
                        class="main_object_description"
                      >
                        <li>» {{element}} </li>
                      </ul>
                      <br>
                    </div>

                    <div class="thirdTitle">
                      <h1 class="main_title text-primary">{{service.thirdTitle}}<br></h1>
                      <h2 class="main_description">{{service.thirdDescription}}<br></h2>
                      <ul
                        v-for="element in service.thirdObjectDescription"
                        :key = element.name
                        class="main_object_description"
                      >
                        <li>» {{element}} </li>
                      </ul>
                      <br>
                    </div>
                  </div>
            </div>
          </expander>
        </template>




      </div>
    </div>
  </div>
</template>


<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import Expander from "../components/Acordion/Accordion.vue";
import Loader from "../components/Loader";

export default {
  name: "ServicesAndClaimsPage",
  data: function () {
    return {
      servicesClaimsList : [
          {
              "id": 1,
              "title": "Accidentes en Viaje",
              "img_insurance":require("./../assets/img/insuranceOptions/Color_Viajes.png"),
              "imagen_xl": require("./../assets/img/services_and_claims/Viajero.png"),
              "width_xl": "1200.00",
              "height_xl": "1920.00",
              "imagen_xs": require("./../assets/img/services_and_claims/Viajero-min.png"),
              "width_xs": "1200.00",
              "height_xs": "6500.00",
              "categoryid": "0",
              "contact": "Toda la República: 55 4624 2440\nCorreo:emergencia@continentalassist.com Whatsapp: +57 31 8554 4699",
              "body": [
                  {
                      "firstTitle" : "Si usted requiere de Asistencia, comuníquese de inmediato a nuestra Central de Asistencia que encontrará en su Voucher y en su tarjeta de bolsillo Continental Assist.  ",
                      "firstDescription" : "",
                      "firstObjectDescription": [],
                      "secondTitle" : "Números Telefónicos de Emergencia ",
                      "secondObjectDescription": [
                          "Argentina: +54-1159841197 ",
                          "Brasil: +55-1139588581 ",
                          "Canadá: +1-6477241724 ",
                          "Chile: +56-229381568 ",
                          "China: +85-258197778 ",
                          "Colombia: 57-15897464 ",
                          "Cuba: 53-78668920 ",
                          "España: 34-911982347 ",
                          "Francia: 33-184889760 ",
                          "Guatemala: 502-23787929 ",
                          "Israel: 97-223748787 ",
                          "Italia: 39-0694801084 ",
                          "México: +52 5546242440 ",
                          "Panamá: 507-8339145 ",
                          "Perú: 51-17085454 ",
                          "Portugal: 35-1308809737 ",
                          "Reino Unido: 44-2036956155 ",
                          "Rep. Dominicana: 1-8299548519 ",
                          "USA: +1-305-722-5824 ",
                          "Whatsapp +573185544699 ",
                          "Si el servicio telefónico desde donde llamase el titular no permitiera esta llamada en forma gratuita, pedir una llamada por cobrar para Estados Unidos, al número 786-613-7102.",
                          "También puede contactarnos por email a la dirección emergencia@continentalassist.com   "
                      ],
                      "thirdTitle" : "NOTA: Las llamadas se pueden hacer desde números locales, públicos y en caso de llamar vía celular, se deberá haber activado las llamadas toll free internacionales.   ",
                      "thirdDescription" : "",
                      "thirdObjectDescription": [
                          "Nombre completo del titular y número de Voucher  ",
                          "Teléfono de contacto ",
                          "Motivo de la emergencia y lugar donde se encuentra   ",
                          "El titular SIEMPRE debe solicitar la autorización de la central de Asistencia de CONTINENTAL ASSIST para tomar cualquier iniciativa o comprometer cualquier gasto en relación a los beneficios otorgados por el Plan de Asistencia.   ",
                          "Si el Titular, presenta un caso de gravedad, lo cual pone en riesgo su integridad, por favor dirigirse al Hospital o Centro Médico más cercano. El Titular del plan o una persona allegada, deberá comunicarse con nuestra Central de Emergencias en un plazo NO MAYOR de 24 horas de haberse originado el caso.   ",
                          "Y recuerda, ante cualquier incidencia llámenos."
                      ]
                  }
              ]
          },
          {
              "id": 2,
              "title": "Accidentes Personales",
              "img_insurance":require("./../assets/img/insuranceOptions/Color_Accidentes_Personales.png"),
              "imagen_xl": require("./../assets/img/services_and_claims/Funerario.png"),
              "width_xl": "1200.00",
              "height_xl": "1570.00",
              "imagen_xs": require("./../assets/img/services_and_claims/Funerario-min.png"),
              "width_xs": "1400.00",
              "height_xs": "5400.00",
              "categoryid": "1",
              "contact": "Toda la República: 800 400 9911",
              "body": [
                  {
                      "firstTitle" : "La reclamación de tu siniestro será como indemnización. ",
                      "firstDescription" : "",
                      "firstObjectDescription": [
                          "Necesitas tener el número de póliza y/o nombre del contratante y/o nombre completo del Aseagurado afectado al momento de realizar la reclamación y teléfono móvil de contacto.  ",
                          "El Call Center verificará la póliza y, si procede, se le dará la siguiente información al Asegurado: ",
                          "Un Número de Reporte (es importante guardarlo). ",
                          "Información del Hospital o Clínica de nuestra red más cercano al lugar del accidente, al cual deberá dirigirse para que se le brinde atención médica. ",
                          "El monto del deducible que el Asegurado deberá cubrir en el hospital (en caso de que aplique). ",
                          "En el hospital se deberán presentar los siguientes documentos: ",
                          "Aviso de Accidente para entregar en el hospital, totalmente llenado, firmado y/o sellado conforme lo especifique su póliza. ",
                          "Identificación oficial vigente con fotografía del Asegurado afectado; si es menor de edad deberá presentar cualquier identificación vigente con fotografía y la identificación oficial vigente con fotografía del padre o tutor. ",
                          "Documentación que acredite la pertenencia a la colectividad (en caso de que su póliza lo precise). ",
                          "Thona Seguros realizará el Pago Directo al hospital, por los montos y conceptos amparados, por lo que el Asegurado solo deberá costear:  ",
                          "El Deducible (en caso de que aplique) y  ",
                          "Los gastos personales no cubiertos durante su estancia en el hospital y los no relacionados al accidente amparado."
                      ],
                      "secondTitle" : "",
                      "secondDescription" : "",
                      "secondObjectDescription": [],
                      "thirdTitle" : "",
                      "thirdDescription" : "",
                      "thirdObjectDescription": []
                  }
              ]          
          },
          {
              "id": 3,
              "title": "Asistencia Medica",
              "img_insurance":require("./../assets/img/insuranceOptions/Color_Vrim.png"),
              "imagen_xl": require("./../assets/img/services_and_claims/Dental.png"),
              "width_xl": "1200.00",
              "height_xl": "620.00",
              "imagen_xs": require("./../assets/img/services_and_claims/Dentegra-min.png"),
              "width_xs": "1200.00",
              "height_xs": "2520.00",
              "categoryid": "0",
              "contact": "Toda la República: 55 4209 3204\nCorreo:contacto@medicavrim.com",
              "body": [
                  {
                      "firstTitle" : "Descarga la aplicación de VRIM ahí puedes encontrar: ",
                      "firstDescription" : "",
                      "firstObjectDescription": [
                          "Acceso a la red médica y de descuentos de forma geolocalizada. ",
                          "Acceso a promociones limitadas especiales. ",
                          "Botón de emergencias médicas. ",
                          "Llamada a médicos certificados, Envío de ambulancia, Doctor a domicilio, Promociones especiales y Códigos de descuentos desde la aplicación. ",
                          "Y lo mejor, ¡tu tarjeta la tienes en tu app!"
                      ],
                      "secondTitle" : "",
                      "secondDescription" : "",
                      "secondObjectDescription": [],
                      "thirdTitle" : "",
                      "thirdDescription" : "",
                      "thirdObjectDescription": []            
                  }
              ]
          },
          {
              "id": 4,
              "title": "Bicicleta",
              "img_insurance":require("./../assets/img/insuranceOptions/Color_Bicicleta.png"),
              "imagen_xl": require("./../assets/img/services_and_claims/Cancer.png"),
              "width_xl": "1200.00",
              "height_xl": "370.00",
              "imagen_xs": require("./../assets/img/services_and_claims/Cancer-min.png"),
              "width_xs": "1200.00",
              "height_xs": "1020.00",
              "categoryid": "0",
              "contact": "Toda la República: 800 017 6288",
              "body": [
                  {
                      "firstTitle" : "Ten a la mano el número de póliza y nombre completo del contratante. ",
                      "firstDescription" : "Te solicitarán la información completa de Cómo, Cuándo y Dónde ocurrió el siniestro.",
                      "firstObjectDescription": [],
                      "secondTitle" : "",
                      "secondDescription" : "",
                      "secondObjectDescription": [],
                      "thirdTitle" : "",
                      "thirdDescription" : "",
                      "thirdObjectDescription": []            
                  }
              ]
          },
          {
              "id": 5,
              "title": "Cáncer",
              "img_insurance":require("./../assets/img/insuranceOptions/Color_Cancer.png"),
              "imagen_xl": require("./../assets/img/services_and_claims/Pyme.png"),
              "width_xl": "1200.00",
              "height_xl": "1020.00",
              "imagen_xs": require("./../assets/img/services_and_claims/Pyme-min.png"),
              "width_xs": "1200.00",
              "height_xs": "3420.00",
              "categoryid": "9",
              "contact": "CDMX y Área Metropolitana: 55 5246 7502 Interior de la república: 800 365 00 24 y 800 062 73 73",
                "body": [
                    {
                        "firstTitle" : "Solicitud de reembolso requisitado (no hay un formato específico para este producto) ",
                        "firstDescription" : "",
                        "firstObjectDescription": [
                            "Informe médico requisitado en formato Mapfre ",
                            "Historia Clínica completa ",
                            "Estudios que confirmen el diagnóstico definitivo. ",
                            "En caso de realizarse cirugía se debe anexar: ",
                            "Notas quirúrgicas ",
                            "Notas Postquirúrgicas ",
                            "Estudio de patología (extirpación de tumor u órgano) ",
                            "Documentos de Artículo 492 cotejados los cuales son: ",
                            "Identificación oficial vigente ",
                            "Comprobante de domicilio no mayor a tres meses de antigüedad ",
                            "Estado de cuenta Bancario donde se indique la CLABE INTERBANCARIA. ",
                            "Recordemos que los documentos antes indicados son los mínimos requeridos para el ingreso a dictamen, si en la revisión médica que se realiza se llegara a requerir alguna información adicional, se les hará saber por los conductos correspondientes."
                        ],
                        "secondTitle" : "",
                        "secondDescription" : "",
                        "secondObjectDescription": [],
                        "thirdTitle" : "",
                        "thirdDescription" : "",
                        "thirdObjectDescription": []
                    }
                ]
          },
          {
              "id": 6,
              "title": "Covid",
              "img_insurance":require("./../assets/img/insuranceOptions/Color_Covid.png"),
              "imagen_xl": require("./../assets/img/services_and_claims/Covid_Quirurgico.png"),
              "width_xl": "1200.00",
              "height_xl": "1020.00",
              "imagen_xs": require("./../assets/img/services_and_claims/Covid_Quirurgico-min.png"),
              "width_xs": "1200.00",
              "height_xs": "2820.00",
              "categoryid": "4",
              "contact": "Toda la República: 800 890 6824",
              "body": [
              {
                  "firstTitle" : "La reclamación de tu siniestro será como indemnización.  ",
                  "firstDescription" : "",
                  "firstObjectDescription": [
                      "Para que tengas un servicio inmediato y de calidad, habla a nuestro Centro de Atención Telefónica (CAT) Lada sin Costo 01800-890-6824 el cual opera las 24 horas del día, los 365 días del año.   ",
                      "Ellos te solicitarán la siguiente información. ",
                      "Número de póliza ",
                      "Nombre del contratante ",
                      "Nombre completo del Asegurado afectado al momento de realizar la reclamación.  "
                  ],
                  "secondTitle" : "Los documentos que piden son: ",
                  "secondDescription" : "",
                  "secondObjectDescription": [
                      "Aviso de accidente o enfermedad requisitado. ",
                      "Identificación oficial del Asegurado afectado. ",
                      "Informe médico requisitado. "
                  ],
                  "thirdTitle" : "",
                  "thirdDescription" : "",
                  "thirdObjectDescription": []
              }
              ]          

          },
          {
              "id": 7,
              "title": "Dental",
              "img_insurance":require("./../assets/img/insuranceOptions/Color_Dental.png"),
              "imagen_xl": require("./../assets/img/services_and_claims/Hogar.png"),
              "width_xl": "1200.00",
              "height_xl": "1020.00",
              "imagen_xs": require("./../assets/img/services_and_claims/Hogar-min.png"),
              "width_xs": "1200.00",
              "height_xs": "3020.00",
              "categoryid": "0",
              "contact": "Toda la República: 55 5002 3102",
              "body": [
                  {
                      "firstTitle" : "La atención de siniestros es 24×7, pero si lo que deseas es agendar una consulta, será necesario acceder a cualquiera de los siguiente links: https://dentegra.secure.force.com/busqueda/dentegra_ubicacion_iconos?se=d https://providers.tusegurodentaldentegra.com.mx/ ",
                      "firstDescription" : "",
                      "firstObjectDescription": [
                          "Pones tu código postal y te aparecen todos los dentistas cerca de tu domicilio.  ",
                          "La búsqueda también la puedes realizar por nombre del dentista o especialidad  ",
                          "Te van a solicitar los siguientes datos.  "
                      ],
                      "secondTitle" : "Una vez que seleccionaste al dentista, seleccionas la opción de primera cita  ",
                      "secondDescription" : "",
                      "secondObjectDescription": [
                          "Nombre (s) ",
                          "Apellido (s) ",
                          "Fecha de nacimiento ",
                          "Nombre de contacto ",
                          "Teléfono celular ",
                          "Correo electrónico ",
                          "Número de póliza o membresía ",
                          "Seleccionar como te gustaría que te contactarán."
                      ],
                      "thirdTitle" : "",
                      "thirdDescription" : "",
                      "thirdObjectDescription": []
                  }
              ]
          },
          {
              "id": 8,
              "title": "Funerario",
              "img_insurance":require("./../assets/img/insuranceOptions/Color_Funerario.png"),
              "imagen_xl": require("./../assets/img/services_and_claims/VRIM.png"),
              "width_xl": "1200.00",
              "height_xl": "1220.00",
              "imagen_xs": require("./../assets/img/services_and_claims/VRIM-min.png"),
              "width_xs": "1100.00",
              "height_xs": "3520.00",
              "categoryid": "6",
              "contact": "Toda la República: 800 890 6824",
              "body": [
                  {
                      "firstTitle" : "La reclamación de tu siniestro será como indemnización. ",
                      "firstDescription" : "Para que tengas un servicio inmediato y de calidad, habla a nuestro Centro de Atención Telefónica (CAT) Lada sin Costo 01800-890-6824 el cual opera las 24 horas del día, los 365 días del año.  ",
                      "firstObjectDescription": [
                          "Ellos te solicitarán la siguiente información: ",
                          "Solicitud Reclamación de Pago de Siniestro Seguro de Vida.* ",
                          "Copia del acta de nacimiento del Asegurado. ",
                          "Copia certificada del acta de defunción. ",
                          "Copia de la identificación oficial del Asegurado. ",
                          "Copia del acta de nacimiento de (los) beneficiario(s). ",
                          "Copia de la identificación oficial del o los beneficiario(s). ",
                          "En caso de que el beneficiario sea la esposa(o), concubina(o) se requiere original o copia certificada del acta de matrimonio actualizada o acreditación de concubinato ante autoridad judicial. ",
                          "Copia de comprobante de domicilio reciente con antigüedad no mayor 3 meses ",
                          "Copia del encabezado del estado de cuenta bancario en donde sea visible la cuenta clabe, nombre del banco y nombre del titular de la cuenta a la cual se realizará el reembolso."
                      ],
                      "secondTitle" : "",
                      "secondDescription" : "",
                      "secondObjectDescription": [],
                      "thirdTitle" : "",
                      "thirdDescription" : "",
                      "thirdObjectDescription": []            
                  }
              ]
          },
          {
              "id": 9,
              "title": "Hogar",
              "img_insurance":require("./../assets/img/insuranceOptions/Color_Hogar.png"),
              "imagen_xl": require("./../assets/img/services_and_claims/Bicicleta.png"),
              "width_xl": "1200.00",
              "height_xl": "620.00",
              "imagen_xs": require("./../assets/img/services_and_claims/Bicicleta-min.png"),
              "width_xs": "1200.00",
              "height_xs": "2020.00",
              "categoryid": "0",
              "contact": "CDMX, Monterrey y Guadalajara: 55 1253 3737 Interior de la república: 800 716 54 67",
              "body": [
                  {
                      "firstTitle" : "Necesitas tener tu póliza a la mano y la aseguradora te solicitara los siguientes datos: ",
                      "firstDescription" : "",
                      "firstObjectDescription": [
                          "Número de póliza. ",
                          "Nombre del asegurado. ",
                          "Fecha y hora del accidente. ",
                          "Lugar del accidente. ",
                          "Persona a contactar y teléfono. ",
                          "Causa del daño. ",
                          "Bienes afectados"
                      ],
                      "secondTitle" : "",
                      "secondDescription" : "",
                      "secondObjectDescription": [],
                      "thirdTitle" : "",
                      "thirdDescription" : "",
                      "thirdObjectDescription": []            
                  }
              ]
          },
          {
              "id": 10,
              "title": "Mascotas",
              "img_insurance":require("./../assets/img/insuranceOptions/Color_Mascotas.png"),
              "imagen_xl": require("./../assets/img/services_and_claims/Mascotas.png"),
              "width_xl": "1200.00",
              "height_xl": "1920.00",
              "imagen_xs": require("./../assets/img/services_and_claims/Mascotas-min.png"), //Minificado
              "width_xs": "1200.00",
              "height_xs": "6100.00",
              "categoryid": "8",
              "contact": "CDMX y Área Metropolitana: 55 5584 1736 Interior de la república: 800 2153 455 Correo:siniestros@gmx.com.mx",
              "body": [
                  {
                      "firstTitle" : "En caso de que tu mascota tenga algún siniestro, este aplica vía reembolso. Necesitas contar con la siguiente información. ",
                      "firstDescription" : "",
                      "firstObjectDescription": [
                          "Tu identificación oficial ",
                          "Carta de reclamación detallando el accidente o enfermedad ",
                          "Informe médico: deberás solicitar este documento al (los) médico(s) que haya(n) intervenido en mi diagnóstico o tratamiento de la enfermedad o accidente ",
                          "Historia clínica completa de tu mascota, así como su carnet de vacunación. ",
                          "Radiografías, análisis de laboratorio, electrocardiogramas, encefalogramas y demás elementos probatorios así como sus interpretaciones, según el caso. ",
                          "Comprobantes de gastos que deberán reunir requisitos fiscales a tu nombre ",
                          "Facturas de medicamentos. Todas las facturas de medicamentos deberán acompañarse de la receta expedida por el (los) médico(s) veterinarios. ",
                          "Copia de cartilla de vacunación.  "
                      ],
                      "secondTitle" : "Las facturas para reembolso de Vacuna o Desparasitación, Baño, Estética u Hospedaje deberán solicitarse a nombre de:  ",
                      "secondDescription" : "",
                      "secondObjectDescription": [
                          "Razón Social: MAS SERVICIOS ASISTENCIALES, S.A. DE C.V. ",
                          "RFC: MSA120306BK5 ",
                          "Domicilio Fiscal: TECOYOTITLA 412 EXHACIENDA DE GUADALUPE CHIMALISTAC, C.P. 01050, ÁLVARO OBREGÓN, CDMX ",
                          "Método de Pago: EN UNA SOLA EXHIBICIÓN ",
                          "Cuenta destino: 1402 ",
                          "Forma de Pago: TRANSFERENCIA ELECTRÓNICA DE FONDOS ",
                          "Uso de CFDI: G03 GASTOS EN GENERAL  "
                      ],
                      "thirdTitle" : "Adjuntar copia de los siguientes documentos:  ",
                      "thirdDescription" : "",
                      "thirdObjectDescription": [
                          "a) Identificación oficial por ambos lados ",
                          "b) Encabezado de estado de cuenta donde se vea cuenta o clave para transferir reembolso. ",
                          "c) Para reembolso de Hospedaje: copia de factura o comprobante de hospitalización del asegurado  ",
                          "Enviar este formato y los documentos solicitados al correo: reembolsos@masservicios.com.mx"
                      ]            
                    }
                  ],
          },
          {
              "id": 11,
              "title": "Pyme",
              "img_insurance":require("./../assets/img/insuranceOptions/Color_PyME.png"),
              "imagen_xl": require("./../assets/img/services_and_claims/Viajero.png"),
              "width_xl": "1200.00",
              "height_xl": "920.00",
              "imagen_xs": require("./../assets/img/services_and_claims/Viajero-min.png"),
              "width_xs": "1200.00",
              "height_xs": "2820.00",
              "categoryid": "9",
              "contact": "CDMX y Área Metropolitana: 55 5246 7502 Interior de la república: 800 365 00 24 y 800 062 73 73",
              "body": [
                  {
                      "firstTitle" : "Al momento de reportar tu siniestro es importante tener los siguientes datos a la mano: ",
                      "firstDescription" : "",
                      "firstObjectDescription": [
                          "Número de póliza de seguro ",
                          "Nombre del contratante ",
                          "Teléfono de contacto ",
                          "Nombre de la persona a contactar. (En caso de que no sea la misma persona la que reporta el siniestro) Ubicación del siniestro:  ",
                          "Dirección completa ",
                          "Calle ",
                          "Número Colonia ",
                          "CP ",
                          "Puntos de referencia ",
                          "El ejecutivo le asignará a un Ajustador Profesional quien de forma inmediata se pondrá en contacto con usted, concertará una cita y le asesorará en el proceso de integración de su reclamación."
                      ],
                      "secondTitle" : "",
                      "secondDescription" : "",
                      "secondObjectDescription": [],
                      "thirdTitle" : "",
                      "thirdDescription" : "",
                      "thirdObjectDescription": []            
                  }
              ]
          },
          {
              "id": 12,
              "title": "Quirúrgico",
              "img_insurance":require("./../assets/img/insuranceOptions/Color_Quirurgico.png"),
              "imagen_xl": require("./../assets/img/services_and_claims/Covid_Quirurgico.png"),
              "width_xl": "1200.00",
              "height_xl": "870.00",
              "imagen_xs": require("./../assets/img/services_and_claims/Covid_Quirurgico-min.png"),
              "width_xs": "1200.00",
              "height_xs": "3200.00",
              "categoryid": "11",
              "contact": "Toda la República: 800 890 6824",
              "body": [
                  {
                      "firstTitle" : "La reclamación de tu siniestro será como indemnización.  ",
                      "firstDescription" : "",
                      "firstObjectDescription": [
                          "Para que tengas un servicio inmediato y de calidad, habla a nuestro Centro de Atención Telefónica (CAT) Lada sin Costo 01800-890-6824 el cual opera las 24 horas del día, los 365 días del año.   ",
                          "Ellos te solicitarán la siguiente información. ",
                          "Número de póliza ",
                          "Nombre del contratante ",
                          "Nombre completo del Asegurado afectado al momento de realizar la reclamación.  "
                      ],
                      "secondTitle" : "Los documentos que piden son: ",
                      "secondDescription" : "",
                      "secondObjectDescription": [
                          "Aviso de accidente o enfermedad requisitado. ",
                          "Identificación oficial del Asegurado afectado. ",
                          "Informe médico requisitado."
                      ],
                      "thirdTitle" : "",
                      "thirdDescription" : "",
                      "thirdObjectDescription": []     
                  }
              ]
          }
      ],
      maxWidth: 0,
      isMobileView: null,
      showItems: false,
      isLoading: false,
      downloadUrl: null,
      downloadfilename: null,
      categoryid:0,
    };
  },
  components: {
    Expander,
    Loader,
    FontAwesomeIcon
  },
  computed: {},

  mounted() {
    let self = this;
    this.$store.commit("updateBgApp", "assets/img/questions_background.jpg");
    this.$http
    .get(this.$APIEndpoint + this.$APIServices.frecuent_questions)
      .then(() => {
        // console.log(response)  
    setTimeout(() => {
          if (document.documentElement.clientWidth < 640) {
            // Hay componentes que se deben renderizar solo en la versión web
            self.isMobileView = true;
          } else {
            self.isMobileView = false;
          }
          self.maxWidth = document.querySelector(".Expander").clientWidth;
          self.startInitAnimation();
        }, 500);
      });
  },

  destroyed() {},
  methods: {
    startInitAnimation() {
      let delayBetweenItems = 200;
      this.showItems = true;
      this.$anime.timeline().add({
        targets: ".Expander",
        translateX: (el, i) => {
          if (!(i % 2)) {
            return [-500, 0];
          } else {
            return [500, 0];
          }
        },
        opacity: [0, 1],
        easing: "easeOutExpo",
        duration: 1000,
        delay: (el, i) => delayBetweenItems * (i + 1),
      });
    },
    getScaleImgValues(widthMax, orig_w, orig_h) {
      orig_w = Number(orig_w);
      orig_h = Number(orig_h);
      /* Recalcula los valores de alto y ancho de una imagen para ajustarlos al contenedor */
      //console.log(widthMax, orig_w, orig_h, orig_w / widthMax);
      let divider_factor = orig_w / widthMax;
      return {
        w: orig_w / divider_factor,
        h: orig_h / divider_factor,
      };
    },
    async download_pdfs(categoryidselected) {
      var pdf_data_b64 = []
      this.isLoading = true
      // let self = this;

      var path = this.$APIEndpoint + this.$APIServices.get_info_insurance_pdfs
        await this.$http.get(path)
        .then((response) => {
          // console.log(response)
          // console.log(response.data)

            // const user_id = this.userData.id
            var response_data = response.data
            // console.log(response_data)

            for (let i = 0; i < response_data.length; i++) {

              var categoryid = response_data[i].categoryid

              if(categoryid == categoryidselected){

                // let static_route = "media/ServicesAndClaims/"

                var json_data = {
                  "name":response_data[i].name,
                  "b64_file":response_data[i].b64_file
                }
                pdf_data_b64.push(json_data)

                // console.log(categoryid)
                // if(categoryid == 8){
                //     // Mascotas
                //     static_route += "Gmx/"
                // } else if(categoryid == 4){
                //     // Covid
                //     static_route += "Covid/"
                // } else if(categoryid == 11){
                //     // Quirurgico
                //     static_route += "Quirurgico/"
                // } else if(categoryid == 1){
                //     // Accidentes Personales
                //     static_route += "Accidentes_Personales/"
                // } else if(categoryid == 6){
                //     // Funerario
                //     static_route += "Funerario/"
                // } else if(categoryid == 9){
                //     // Cancer
                //     static_route += "Mapfre/"
                // }


                // static_route += response_data[i].name
                // let routeData = this.$APIServices.Endpoint + static_route;
                // window.open(routeData, '_blank');
              
              }

            }

            for (var data in pdf_data_b64){
              var name_file = pdf_data_b64[data]['name']
              var pdf_b64 = pdf_data_b64[data]['b64_file']
              // const linkSource = `data:application/pdf;base64,${pdf_b64}`;
              // const downloadLink = document.createElement("a");
              // const fileName = `${name_file}.pdf`;
              // downloadLink.href = linkSource;
              // downloadLink.download = fileName;
              // // downloadLink.click();
              // window.open(linkSource, '_blank');


              let pdfWindow = window.open("")
              pdfWindow.document.firstTitle = "fileName";
              
              pdfWindow.document.write(
                  // Auxiliar Tag
                  "<div style='font-size:5px; background-color: black; display: flex; text-align: center; justify-content: center; align-items: center; height:20px;'>"+
                  "<h1 style='cursor:pointer; display:flex; justify-content:center; align-items:center; color: white; text-decoration: none; width:100%; height:100%;'> Asegúrate que tu navegador permita pop-ups y redirecciónamiento de https://sabar.mx"+
                  "</h1>" +
                  "</div>"+

                  "<div style='background-color: #7c5ece; display: flex; text-align: center; justify-content: center; align-items: center; height:100px;'>"+
                  "<a href = 'data:application/pdf;base64," + pdf_b64 + "'"+
                  "download = " + name_file + 
                  " style='cursor:pointer; display:flex; justify-content:center; align-items:center; color: white; text-decoration: none; width:100%; height:100%;'>Oprime aquí para descargar archivo. </a>"+
                  "</div>"+

                  // Iframe
                  "<iframe width='100%' height='100%' src='data:application/pdf;base64," +
                  encodeURI(pdf_b64) + "'></iframe>"
              )
              pdfWindow.document.title = name_file
          
            }
      })
      this.isLoading = false

    },
  },
};
</script>



<style >

.btn_download_documents{
  z-index:100;
  position:absolute;
  bottom:0;
  right:0;
  background-color: orange;
  padding: 8px;
  border-radius: 10px;
}

.Accordion > * {
  max-width: 800px;
}

  .main_logo{
    display: flex;
      align-items:center;
      justify-content:center;
      width:100%;
  }

  .container_info_individual{
    display:flex; 
    flex-direction:column;
    margin: 25px 5px 0 0;
  }

  .container_info {
    margin-top:25px;
    width:100%;
  }


  .servicesAndClaimsExpander{
    
  }

  @media (min-width: 768px) {
    .container_info {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }
  }

  @media (min-width: 1200px) {
    .container_info {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  .justify {
    text-align: justify;
  }

  .main_title {
  
  }

  .main_description {
  }

  .main_object_description {
  }


</style>